.toggle-wrapper {
    display: flex;
    align-items: center;
}
  
.toggle-switch {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 20px;
}


.toggle-container {
    margin-left: 10px;
    margin-right: 10px;
}
.toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    transition: 0.4s;
}

.toggle-slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
}

input:checked + .toggle-slider {
    background-color: #2196f3;
}

input:checked + .toggle-slider:before {
    transform: translateX(28px);
}

/* Light Mode Styles */
.light-mode .label-text {
    color: #444; /* Dark text for light background */
}

.light-mode .info-icon .tooltip-text {
    background-color: #e0e0e0; /* Light background for tooltip */
    color: #333; /* Dark text for tooltip */
}

/* Dark Mode Styles */
.dark-mode .label-text {
    color: #ddd; /* Light text for dark background */
}

.dark-mode .info-icon .tooltip-text {
    background-color: #333; /* Dark background for tooltip */
    color: #fff; /* Light text for tooltip */
}

.info-icon {
    position: relative;
    margin-left: 4px;
    font-size: 12px;
}.tooltip-text {
    visibility: hidden;
    width: 260px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    bottom: 150%; /* Position the tooltip below the icon */
    left: 50%;
    margin-left: -130px; /* Half the width of the tooltip to center it */
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 14px; /* Update the font size */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Add a subtle box-shadow */
    font-family: "Helvetica", Arial, sans-serif; /* Set the font to Helvetica */
    font-weight: normal; /* Set the font-weight to normal */
}


/* Add media query for smaller screens */

@media screen and (max-width: 1500px) {
    .label-text:last-child .tooltip-text {
        right: 0;
        left: auto;
        transform: translateX(25%);
    }
}
  
@media screen and (max-width: 800px) {
    .info-icon .tooltip-text {
        left: 50%;
        margin-left: -65px;
        transform: translateX(-30%);
    }
}
  
.info-icon:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}
  
.toggle-switch input {
    display: none;
}
.toggle-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
}

